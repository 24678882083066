<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackList">未交付订单</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackDetail">订单详情</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackDelivery">发货</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>编辑发货信息</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="container">
      <div class="operation">
        <a-space>
          <!--          <a class="button-item" :href="templateUrl">-->
          <!--            <a-icon type="download" />-->
          <!--            下载序列号导入模板-->
          <!--          </a>-->
          <a class="button-item" @click="download">
            <a-icon type="download" />
            下载发货清单
          </a>
          <a-upload
            name="file"
            accept=".xlsx,.xls"
            :multiple="false"
            :action="importDataUrl"
            :headers="headers"
            :show-upload-list="false"
            @change="onFileStatusChange"
          >
            <a-button
              type="primary"
              class="button-item"
              :disabled="!isSkuImport"
            >
              批量导入
            </a-button>
          </a-upload>
          <a-button class="button-item" @click="onReset">清空</a-button>
        </a-space>
      </div>
      <a-table
        class="table"
        :data-source="tblData"
        :columns="tblColumns"
        :pagination="false"
        :loading="loading"
        row-key="index"
        :scroll="{ x: 1200 }"
      >
        <template
          v-for="column in ['productNumber']"
          :slot="column"
          slot-scope="text, record"
        >
          <div :key="column">
            <a-select
              v-if="record.editable"
              style="width: 100%"
              :default-value="record.productNumber"
              @change="onChangeSkuCode($event, record.key, column)"
            >
              <a-select-option
                v-for="(item, index) in undeliverSkuCodeList"
                :key="index"
                :value="item.skuCode"
              >
                {{ item.skuCode }}
              </a-select-option>
            </a-select>
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template
          v-for="col in ['seriesNumber']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              :disabled="!record.hasSn"
              @change="handleChangeSn($event, record.key, col)"
            />
            <template v-else>
              {{ text ? text : "-" }}
            </template>
          </div>
        </template>
        <template
          v-for="batchCol in ['batchNumber']"
          :slot="batchCol"
          slot-scope="text, record"
        >
          <div :key="batchCol">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              :disabled="!record.hasBatchNo"
              @change="handleChangeSn($event, record.key, batchCol)"
            />
            <template v-else>
              {{ text ? text : "-" }}
            </template>
          </div>
        </template>
        <template
          v-for="quantityCol in ['quantity']"
          :slot="quantityCol"
          slot-scope="text, record"
        >
          <div :key="quantityCol">
            <a-input-number
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              :min="1"
              :max="record.maxValue"
              :formatter="formatInteger"
              :parser="formatInteger"
              :disabled="record.hasSn"
              @change="handleChangeQuantity($event, record.key, quantityCol)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <a-space>
            <a v-if="!record.editable" @click="editCommodity(record.key)">
              编辑
            </a>
            <a v-if="record.editable" @click="saveCommodity(record)">保存</a>
            <a @click="onDelete(record)">删除</a>
            <a
              v-if="!record.editable && record.hasSn"
              @click="onEditPart(record, index)"
            >
              编辑配件
            </a>
          </a-space>
        </template>
      </a-table>
      <div class="add">
        <a-icon
          v-if="isReachLimit"
          type="plus-square"
          :style="{ fontSize: '20px' }"
          class="icon-add"
          @click="handleAddCommodity"
        />
        <p v-if="skuCodeList.length > 0">
          <span v-for="(item, index) in skuCodeList" :key="index">
            商品编码为{{ item.skuCode }}的购买数量为
            <span class="num-red">{{ item.purchaseQuantity }}</span>
            , 已发货数量:
            <span>{{ item.quantityShipped }}</span>
            ;
          </span>
        </p>
      </div>
    </section>

    <div class="examine-container">
      <div class="button-container">
        <a-button class="button-item" @click="onCancel">
          取消
        </a-button>
        <a-button type="primary" class="button-item" @click="onSave">
          保存
        </a-button>
      </div>
    </div>

    <a-modal
      title="编辑配件"
      :visible="showPartVisible"
      width="70%"
      @cancel="showPartVisible = false"
    >
      <template slot="footer">
        <a-button key="back" @click="showPartVisible = false">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="onSavePart">
          保存
        </a-button>
      </template>
      <a-row>
        <a-col :span="8">编码：{{ currentCode }}</a-col>
        <a-col :span="8">
          序列号：{{ currentSeriesNumber ? currentSeriesNumber : "-" }}
        </a-col>
      </a-row>
      <a-table
        class="table"
        :data-source="partTblData"
        :columns="tblColumns"
        :pagination="false"
        :loading="loading"
        row-key="index"
        :scroll="{ x: 800 }"
      >
        <template
          v-for="column in ['productNumber']"
          :slot="column"
          slot-scope="text, record, index"
        >
          <div :key="column">
            <a-select
              v-if="record.editable"
              show-search
              style="width: 100%"
              :filter-option="filterOption"
              :default-value="record.productNumber"
              @change="onChangeBom($event, index, column)"
            >
              <a-select-option
                v-for="(item, index) in bomList"
                :key="index"
                :value="item.skuCode"
                :disabled="item.disabled"
              >
                {{ item.skuCode }}({{ item.skuName }})
              </a-select-option>
            </a-select>
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template
          v-for="col in ['seriesNumber']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              :disabled="!record.hasSn"
              @change="e => handleChangePart(e.target.value, record.key, col)"
            />
            <template v-else>
              {{ text ? text : "-" }}
            </template>
          </div>
        </template>
        <template
          v-for="batchCol in ['batchNumber']"
          :slot="batchCol"
          slot-scope="text, record"
        >
          <div :key="batchCol">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              :disabled="!record.hasBatchNo"
              @change="handleChangePartBatch($event, record.key, batchCol)"
            />
            <template v-else>
              {{ text ? text : "-" }}
            </template>
          </div>
        </template>
        <template
          v-for="quantityCol in ['quantity']"
          :slot="quantityCol"
          slot-scope="text, record"
        >
          <div :key="quantityCol">
            <a-input-number
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              :min="1"
              :max="record.maxValue"
              :formatter="formatInteger"
              :parser="formatInteger"
              :disabled="record.hasSn"
              @change="
                handleChangeQuantityPart($event, record.key, quantityCol)
              "
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a v-if="!record.editable" @click="editPart(record.key)">编辑</a>
            <a v-if="record.editable" @click="savePart(record)">保存</a>
            <a @click="onDeletePart(record)">删除</a>
          </a-space>
        </template>
      </a-table>
      <div class="add">
        <a-icon
          type="plus-square"
          :style="{ fontSize: '20px' }"
          class="icon-add"
          @click="handleAddPart"
        />
      </div>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { buildHeaders } from "../../components/utils";
import { baseURL } from "../../services/HttpService";
import {
  exportShippingList,
  fetchUndeliverByOrderSn,
  verifySkuCodeAndGoods
} from "../../services/OrderService";
import {
  checkDeliverNum,
  fetchBomListBySkuCode,
  fetchNameByCode
} from "../../services/CommodityService";

const tblColumns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 120,
    fixed: "left"
  },
  {
    title: "商品编码",
    dataIndex: "productNumber",
    scopedSlots: { customRender: "productNumber" },
    width: 240
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    width: 240
  },
  {
    title: "序列号",
    dataIndex: "seriesNumber",
    scopedSlots: { customRender: "seriesNumber" },
    width: 180
  },
  {
    title: "批次号",
    dataIndex: "batchNumber",
    scopedSlots: { customRender: "batchNumber" },
    width: 180
  },
  {
    title: "数量",
    dataIndex: "quantity",
    scopedSlots: { customRender: "quantity" },
    width: 120
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 180,
    fixed: "right"
  }
];
export default {
  data() {
    return {
      templateUrl: `${process.env.BASE_URL}序列号导入模板.xlsx`,
      tblData: [],
      tblColumns,
      quantityShipped: "",
      loading: false,
      pagination: {
        hideOnSinglePage: true
      },
      importDataUrl: `${baseURL}/logistics/analysis-excel`,
      headers: buildHeaders(),
      editingKey: "",
      totalQuantity: this.$route.params.totalQuantity,
      orderSn: this.$route.params.orderSn,
      childrenOrderSn: this.$route.params.childrenOrderSn,
      skuCodeList: [],
      type: this.$route.params.type,
      serialSpellList: [],
      showPartVisible: false,
      partTblData: [],
      currentSeriesNumber: "",
      currentCode: "",
      allBomList: [],
      index: "",
      dataLength: 0,
      // 未发货商品编码列表
      undeliverSkuCodeList: []
    };
  },
  computed: {
    isSkuImport() {
      let count = 0;
      this.tblData.forEach(item => {
        if (!item.editable) {
          count += 1;
        }
      });
      return count < this.totalQuantity;
    },
    isReachLimit() {
      return (
        this.tblData.length <
        parseInt(this.totalQuantity) - parseInt(this.quantityShipped)
      );
    },
    bomList() {
      const list = [...this.allBomList];
      list.forEach(bom => {
        if (
          this.partTblData.length > 0 &&
          this.partTblData.filter(data => !data.seriesNumber).length > 0
        ) {
          const filter = this.partTblData.filter(
            data => data.productNumber === bom.skuCode
          );
          if (bom.quantity <= filter.length) {
            bom.disabled = true;
          } else {
            bom.disabled = false;
          }
        } else {
          bom.disabled = false;
        }
      });
      return list;
    }
  },
  mounted() {
    this.fetchUndeliver();
    this.quantityShipped = this.$route.params.quantityShipped;
    if (typeof this.childrenOrderSn !== "undefined") {
      localStorage.setItem("serialOrderSn", this.childrenOrderSn);
    }
    const serialSpellList = localStorage.getItem("serialSpellList");
    if (serialSpellList !== null) {
      const list = JSON.parse(serialSpellList);
      this.serialSpellList = list;
      if (list.length > 0) {
        list.forEach(item => {
          if (item.orderSn === this.childrenOrderSn) {
            this.tblData = item.list;
          }
        });
      }
    }
    if (localStorage.getItem("skuCodeList") !== null) {
      this.skuCodeList = JSON.parse(localStorage.getItem("skuCodeList"));
    }
  },
  methods: {
    onBackList() {
      this.$router.replace({ name: "UndeliveredOrder" });
    },
    onBackDetail() {
      this.$router.replace({
        name: "UndeliveredDetail",
        params: { procureOrderSn: this.orderSn }
      });
    },
    onBackDelivery() {
      this.$router.go(-1);
    },
    // 清空
    onReset() {
      this.tblData = [];
      if (this.serialSpellList && this.serialSpellList.length > 0) {
        for (let i = 0; i < this.serialSpellList.length; i += 1) {
          if (this.serialSpellList[i] === this.childrenOrderSn) {
            this.serialSpellList.splice(i, 1);
          }
        }
      }
      this.serialSpellList = [];
    },
    // 文件解析
    onFileStatusChange(info) {
      this.dataLength = this.tblData.length;
      if (info.file.status === "done") {
        if (info.file.response.code === "SUCCESS") {
          this.$message.success("文件解析成功");
          const data = info.file.response.data;
          if (data.length > 0 && data.length <= this.totalQuantity) {
            data.forEach((item, index) => {
              item.index = index + 1;
              item.key = index;
              item.editable = false;
            });
          } else if (data.length > this.totalQuantity) {
            data.forEach((item, index) => {
              item.index = index + 1;
              item.key = index;
              item.editable = false;
            });
          }
          this.setData(data);
        } else {
          this.$message.error("导入失败");
        }
      } else if (info.file.status === "error") {
        this.$message.error("导入失败");
      }
    },
    // 将解析的数据放入表格中
    setData(data) {
      if (data && data.length > 0) {
        const that = this;
        data.sort((a, b) => a.pseriesNumber - b.pseriesNumber);
        this.dataCheck(data, function(err) {
          if (err) {
            that.$notification.open({
              message: "导入情况展示",
              description: err,
              duration: null
            });
          }
        });
      }
    },
    // 对解析的数据进行校验
    async dataCheck(data, callback) {
      let errStr = "";
      let successCount = 0;
      let errorCount = 0;
      let saveCount = 0;

      for (const item of data) {
        if (item.hasSn === "是") {
          item.hasSn = true;
        } else {
          item.hasSn = false;
        }
        if (item.hasBatchNo === "是") {
          item.hasBatchNo = true;
        } else {
          item.hasBatchNo = false;
        }
        if (
          !item.pseriesNumber &&
          saveCount >=
            this.totalQuantity - this.quantityShipped - this.dataLength
        ) {
          if (errStr) {
            errStr += "；";
          }
          errStr += "超出商品购买数量上限，超出的部分商品序列号自动忽略";
          break;
        }
        let errMsg = "";
        if (item.seriesNumber) {
          errMsg = this.checkSkuGoodsSn(
            item.seriesNumber,
            item.productNumber,
            item.pseriesNumber === null
          );
        }
        if (errMsg) {
          // 需要特殊处理的异常消息
          if (errStr) {
            errStr += "；";
          }
          errStr += errMsg;
          errorCount += 1;
        } else {
          let filter = [];
          if (item.pseriesNumber) {
            filter = this.tblData.filter(
              data => data.seriesNumber === item.pseriesNumber
            );
          }
          const params = {
            orderSn: this.childrenOrderSn,
            pskuCode: filter.length > 0 ? filter[0].productNumber : null,
            skuCode: item.productNumber,
            goodsSn: item.seriesNumber,
            pgoodsSn: null
          };
          await verifySkuCodeAndGoods(params).then(async resp => {
            if (resp.data.code === "SUCCESS") {
              let name = "";
              await this.fetchProductName(item.productNumber).then(res => {
                name = res;
              });
              const newData = {
                index: this.tblData.length + 1,
                key: this.tblData.length,
                productNumber: item.productNumber,
                seriesNumber: item.seriesNumber,
                pskuSn: item.pseriesNumber,
                editable: false,
                productName: name,
                quantity: item.quantity,
                hasSn: item.hasSn,
                hasBatchNo: item.hasBatchNo,
                batchNumber: item.batchNo
              };
              let bool = false;
              if (newData.pskuSn) {
                this.tblData.forEach(data => {
                  if (data.seriesNumber === newData.pskuSn) {
                    if (data.subGoodsList) {
                      data.subGoodsList.push(newData);
                    } else {
                      data.subGoodsList = [newData];
                    }
                    bool = true;
                  }
                });
              }
              if (!bool) {
                this.tblData.push(newData);
                saveCount += 1;
              }
              successCount += 1;
            }
            if (resp.data.code === "FAILURE_MESSAGE") {
              // 需要特殊处理的异常消息
              if (errStr) {
                errStr += "；";
              }
              errStr += resp.data.errorMsg;
              errorCount += 1;
            }
          });
        }
      }
      if (errStr) {
        errStr = `添加成功数量：${successCount}，添加失败数量：${errorCount}，失败原因：${errStr}`;
      } else {
        errStr = `添加成功数量：${successCount}，添加失败数量：${errorCount}`;
      }
      callback(errStr);
    },
    // 根据订单编号查询未发货信息
    fetchUndeliver() {
      fetchUndeliverByOrderSn(this.childrenOrderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.undeliverSkuCodeList = data;
          } else {
            this.undeliverSkuCodeList = [];
          }
        }
      });
    },
    // 添加一条记录
    handleAddCommodity() {
      const dataSource = this.tblData;
      const newData = {
        index: this.tblData.length + 1,
        key: this.tblData.length,
        productNumber: "",
        seriesNumber: "",
        batchNumber: "",
        hasBatchNo: false,
        hasSn: false,
        editable: true,
        quantity: "",
        maxValue: 1
      };
      this.tblData = [...dataSource, newData];
    },
    // 更换skuCode
    onChangeSkuCode(value, key, column) {
      const item = this.undeliverSkuCodeList.find(ele => ele.skuCode === value);
      const obj = this.skuCodeList.find(ele => ele.skuCode === value);
      const newData = [...this.tblData];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.tblData = newData;
      }
      this.tblData[key].productName = item.skuName;
      this.tblData[key].productNumber = item.skuCode;
      this.tblData[key].hasBatchNo = item.hasBatchNo;
      this.tblData[key].hasSn = item.hasSn;
      this.tblData[key].maxValue = obj.purchaseQuantity - obj.quantityShipped;
      if (item.hasSn) {
        this.tblData[key].quantity = 1;
      }
    },
    // 序列号、批次号变化
    handleChangeSn(e, key, column) {
      const newData = [...this.tblData];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = e.target.value;
        this.tblData = newData;
      }
    },
    // 数量变化
    handleChangeQuantity(value, key, column) {
      const newData = [...this.tblData];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.tblData = newData;
      }
    },
    // 限制小数
    formatInteger(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      }
      return 0;
    },
    // 编辑
    editCommodity(key) {
      const newData = [...this.tblData];
      const target = newData.find(item => key === item.key);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.tblData = newData;
      }
    },
    // 保存
    saveCommodity(record) {
      if (record.productNumber) {
        // 根据hasSn处理
        this.dealData(record);
      } else {
        const newData = [...this.tblData];
        const target = newData.find(item => record.key === item.key);
        if (target) {
          target.editable = false;
          this.tblData = newData;
        }
        this.editingKey = "";
        let i = 0;
        while (i < this.tblData.length) {
          const data = this.tblData[i];
          if (data.key === record.key) {
            this.tblData.splice(i, 1);
          }
          i = i + 1;
        }
      }
    },
    // 数据处理
    dealData(record) {
      if (record.hasSn) {
        if (this.verificate(record)) {
          this.saveHasSn(record);
        }
      } else {
        if (this.verificate(record)) {
          this.saveWithoutSn(record);
        }
      }
    },
    // 序列号、批次号校验
    verificate(record) {
      let isComplete = true;
      if (record.hasSn && !record.seriesNumber) {
        this.$message.info("请输入序列号");
        isComplete = false;
        return;
      }
      if (record.hasBatchNo && !record.batchNumber) {
        this.$message.info("请输入批次号");
        isComplete = false;
        return;
      }
      return isComplete;
    },
    // 保存有序列号的
    saveHasSn(record) {
      const errMsg = this.checkSkuGoodsSn(
        record.seriesNumber,
        record.productNumber,
        true
      );
      if (errMsg) {
        this.$message.error(errMsg);
        return;
      }
      const list = [];
      this.tblData.forEach(item => list.push(item.seriesNumber));
      const params = {
        goodsSn: record.seriesNumber,
        orderSn: this.childrenOrderSn,
        pskuCode: null,
        skuCode: record.productNumber,
        pgoodsSn: null
      };

      verifySkuCodeAndGoods(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const newData = [...this.tblData];
          const target = newData.find(item => record.key === item.key);
          if (target) {
            target.editable = false;
            this.tblData = newData;
          }
          this.editingKey = "";
        }
        if (resp.data.code === "FAILURE_MESSAGE") {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 保存没有序列号的
    saveWithoutSn(record) {
      if (record.quantity) {
        const newData = [...this.tblData];
        const target = newData.find(item => record.key === item.key);
        if (target) {
          target.editable = false;
          this.tblData = newData;
        }
      } else {
        this.$message.info("请输入数量");
      }
    },
    // 删除
    onDelete(record) {
      this.tblData.splice(record.key, 1);
      this.tblData.forEach((item, index) => {
        item.key = index;
        item.index = index + 1;
      });
    },
    // 配件加一条数据
    handleAddPart() {
      const dataSource = this.partTblData;
      const newData = {
        index: this.partTblData.length + 1,
        key: this.partTblData.length,
        productNumber: "",
        seriesNumber: "",
        batchNumber: "",
        editable: true,
        hasSn: false,
        hasBatchNo: false,
        quantity: "",
        maxValue: 1
      };
      this.partTblData = [...dataSource, newData];
    },
    onChangeBom(value, index, col) {
      const item = this.allBomList.find(ele => ele.skuCode === value);
      const newData = [...this.partTblData];
      const target = newData.find(item => index === item.key);
      if (target) {
        target[col] = value;
        this.partTblData = newData;
      }
      this.partTblData[index].productName = item.skuName;
      this.partTblData[index].hasBatchNo = item.hasBatchNo;
      this.partTblData[index].hasSn = item.hasSn;
      this.partTblData[index].maxValue = item.quantity;
      if (item.hasSn) {
        this.partTblData[index].quantity = 1;
      }
    },
    handleChangePart(value, key, column) {
      const newData = [...this.partTblData];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.partTblData = newData;
      }
    },
    onEditPart(record, index) {
      this.index = index;
      const params = {
        factoryId: localStorage.getItem("factoryId"),
        carSkuCode: record.productNumber
      };
      fetchBomListBySkuCode(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          let subGoodsList = record.subGoodsList;
          if (subGoodsList && subGoodsList.length > 0) {
            subGoodsList.forEach((ele, index) => {
              ele.index = index + 1;
              ele.key = index;
            });
          } else {
            subGoodsList = [];
          }
          this.partTblData = subGoodsList;
          if (data && data.length > 0) {
            this.allBomList = data;
          } else {
            this.allBomList = [];
          }
          this.showPartVisible = true;
          this.currentSeriesNumber = record.seriesNumber;
          this.currentCode = record.productNumber;
        } else {
          this.showPartVisible = false;
        }
      });
    },
    handleChangePartBatch(e, key, column) {
      const newData = [...this.partTblData];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = e.target.value;
        this.partTblData = newData;
      }
    },
    handleChangeQuantityPart(value, key, column) {
      const newData = [...this.partTblData];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.partTblData = newData;
      }
    },
    // 单行配件保存
    savePart(record) {
      if (record.productNumber) {
        // 根据hasSn处理
        this.dealPartData(record);
      } else {
        const newData = [...this.partTblData];
        const target = newData.find(item => record.key === item.key);
        if (target) {
          target.editable = false;
          this.partTblData = newData;
        }
        this.editingKey = "";
        let i = 0;
        while (i < this.partTblData.length) {
          const data = this.partTblData[i];
          if (data.key === record.key) {
            this.partTblData.splice(i, 1);
          }
          i = i + 1;
        }
      }
    },
    dealPartData(record) {
      if (record.hasSn) {
        if (this.verificate(record)) {
          this.saveHasSnPart(record);
        }
      } else {
        if (this.verificate(record)) {
          this.saveWithoutSnPart(record);
        }
      }
    },
    // 配件保存有序列号的
    saveHasSnPart(record) {
      const errMsg = this.checkSkuGoodsSn(
        record.seriesNumber,
        record.productNumber,
        false
      );
      if (errMsg) {
        this.$message.error(errMsg);
        return;
      }
      const params = {
        goodsSn: record.seriesNumber,
        orderSn: this.childrenOrderSn,
        pskuCode: this.currentCode,
        skuCode: record.productNumber,
        pgoodsSn: this.currentSeriesNumber
      };
      verifySkuCodeAndGoods(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const newData = [...this.partTblData];
          const target = newData.find(item => record.key === item.key);
          if (target) {
            target.editable = false;
            this.partTblData = newData;
          }
          this.editingKey = "";
        }
        if (resp.data.code === "FAILURE_MESSAGE") {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 保存没有序列号的
    saveWithoutSnPart(record) {
      if (record.quantity) {
        const newData = [...this.partTblData];
        const target = newData.find(item => record.key === item.key);
        if (target) {
          target.editable = false;
          this.partTblData = newData;
        }
      } else {
        this.$message.info("请输入数量");
      }
    },
    editPart(key) {
      const newData = [...this.partTblData];
      const target = newData.find(item => key === item.key);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.partTblData = newData;
      }
    },
    onDeletePart(record) {
      this.partTblData.splice(record.key, 1);
      if (this.partTblData.length > 1) {
        this.partTblData.forEach((item, index) => {
          item.key = index;
          item.index = index + 1;
        });
      }
    },
    // 配件弹窗保存
    onSavePart() {
      const filter = this.partTblData.filter(item => item.editable);
      if (filter.length > 0) {
        this.$message.error("存在未保存的配件，无法关闭当前弹窗");
      } else {
        this.tblData[this.index].subGoodsList = this.partTblData;
        this.showPartVisible = false;
      }
    },
    download() {
      exportShippingList(this.childrenOrderSn).then(resp => {
        if (resp.status === 200) {
          this.excelDownload(resp.data);
        }
      });
    },
    excelDownload(data) {
      this.$message.success("解析成功，开始下载");
      const link = document.createElement("a");
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(`${this.childrenOrderSn}.xlsx`));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 商品序列号重复校验
    checkSkuGoodsSn(goodsSn, productSn, isParent) {
      if (isParent) {
        const tblData = this.tblData.filter(
          data => !data.editable && data.seriesNumber === goodsSn
        );
        if (tblData.length > 0) {
          return `输入的序列号${goodsSn}重复,编码为${productSn}`;
        }
      } else {
        const tblData = this.tblData.filter(
          data => !data.editable && data.seriesNumber === goodsSn
        );
        if (tblData.length > 1) {
          return `输入的序列号${goodsSn}重复,编码为${productSn}`;
        }
      }
      // 配件输入校验
      const subTblData = this.partTblData.filter(
        data => !data.editable && data.seriesNumber === goodsSn
      );
      if (subTblData.length > 0) {
        return `输入的序列号${goodsSn}重复,编码为${productSn}`;
      }
      // 配件导入校验
      for (const data of this.tblData) {
        if (data.subGoodsList && data.subGoodsList.length > 0) {
          const filter = data.subGoodsList.filter(
            d => !d.editable && d.seriesNumber === goodsSn
          );
          if (filter.length > 0) {
            return `输入的序列号${goodsSn}重复,编码为${productSn}`;
          }
        }
      }
      return null;
    },
    // 查询产品名称
    async fetchProductName(code) {
      let name = "";
      const params = {
        factoryId: localStorage.getItem("factoryId"),
        skuCode: code
      };
      await fetchNameByCode(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data) {
            name = data;
          } else {
            name = "";
          }
        }
      });
      return name;
    },
    onCancel() {
      this.$router.go(-1);
    },
    onSave() {
      this.$store.state.isModify = false;
      if (this.tblData.length === 0) {
        this.$message.info("请先编辑发货信息");
      } else {
        const isNotSave = this.tblData.some(ele => ele.editable);
        if (isNotSave) {
          this.$message.info("请先确认编辑");
        } else {
          const forms = this.tblData;
          forms.forEach(item => {
            item.pskuSn = null;
            item.skuCode = item.productNumber;
            item.skuSn = item.seriesNumber;
            item.batchNo = item.batchNumber;
            if (item.subGoodsList && item.subGoodsList.length > 0) {
              item.subGoodsList.forEach(ele => {
                ele.pskuSn = item.seriesNumber;
                ele.skuCode = ele.productNumber;
                ele.skuSn = ele.seriesNumber;
                ele.batchNo = ele.batchNumber;
              });
            }
          });
          checkDeliverNum(this.childrenOrderSn, forms).then(resp => {
            if (resp.data.code === "SUCCESS") {
              if (localStorage.getItem("serialSpellList") !== null) {
                const list = JSON.parse(
                  localStorage.getItem("serialSpellList")
                );
                let isExist = false;
                if (list.length > 0) {
                  list.forEach(item => {
                    if (item.orderSn === this.childrenOrderSn) {
                      item.list = this.tblData;
                      isExist = true;
                    } else {
                      isExist = false;
                    }
                  });
                }
                if (!isExist) {
                  const params = {
                    orderSn: this.childrenOrderSn,
                    list: this.tblData
                  };
                  list.push(params);
                }
                localStorage.setItem("serialSpellList", JSON.stringify(list));
              } else {
                const list = [
                  {
                    orderSn: this.childrenOrderSn,
                    list: this.tblData
                  }
                ];
                localStorage.setItem("serialSpellList", JSON.stringify(list));
              }
              this.$router.replace({
                name: "DeliverGoods",
                params: { procureOrderSn: this.orderSn }
              });
            } else {
              this.$message.info("请填写完整");
            }
          });
        }
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "DeliverGoods") {
      localStorage.removeItem("serialList");
      localStorage.removeItem("serialSpellList");
    }
    next();
  }
};
</script>

<style scoped>
.container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 80px 20px;
  background-color: white;
}

.operation {
  display: flex;
  justify-content: flex-end;
}

.table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.button-container {
  display: flex;
}

.button-item {
  margin-right: 15px;
}

.add {
  display: flex;
}

.num-red {
  color: red;
}

.icon-add {
  margin-right: 10px;
}
.delete-btn {
  margin-left: 20px;
}
</style>
