import { apiClient } from "./HttpService";

// 获取商品列表
export function fetchCommodityList(params) {
  return apiClient.get(
    `/commodities?commodityStatus=${params.commodityStatus}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&productCode=${params.productCode}&productName=${params.productName}&productStatus=${params.productStatus}&productType=${params.productType}`
  );
}

// 查询商品详情
export function commodityDetail(productId) {
  return apiClient.get(`/commodities/${productId}`);
}

// 批量修改商品上下架状态
export function modifyCommodityStatus(params) {
  return apiClient.patch(
    `/commodities?onshelfFlag=${params.onshelfFlag}`,
    params.productIds
  );
}

// 根据产品编码查询名称
export function fetchNameByCode(params) {
  return apiClient.get(
    `/sku/${params.factoryId}/sku-name?skuCode=${params.skuCode}`
  );
}

// 根据商品编码查询Bom
export function fetchBomListBySkuCode(params) {
  return apiClient.get(
    `/${params.factoryId}/sku-bom?carSkuCode=${params.carSkuCode}`
  );
}

// 检查发货数量
export function checkDeliverNum(orderSn, forms) {
  return apiClient.post(`/logistics/${orderSn}/verification-goods-num`, forms);
}

// 查询整车或配件序列号是否在使用
export function checkGoodsSeriesNumber(goodsSn, corporationId) {
  return apiClient.get(
    `/inventories/goods/${goodsSn}?corporationId=${corporationId}`
  );
}

// 查询零件序列号是否存在
export function checkPartSeriesNumber(goodsSn) {
  return apiClient.get(`/inventories/parts/${goodsSn}`);
}

// 原厂发货
export function factoryDeliverGoods(params) {
  return apiClient.post(`/consignment/pc`, params);
}
